<template>
  <main>
    <TheHero v-if="theHeroProps.promotion"
             v-bind="theHeroProps" />
    <TheCatalog v-if="theCatalogProps.categories.length"
                v-bind="theCatalogProps" />
    <TheAbout />
    <ThePortfolio />
    <TheContacts />
  </main>
</template>

<script setup lang="ts">
import {
  TheHero,
  TheCatalog,
  TheAbout,
  ThePortfolio,
  TheContacts,
  ITheHeroProps,
  ITheCatalogProps,
} from '@/components';
import { IMainPage } from '@/types';

const { findOne } = useStrapi();
const response = await findOne<IMainPage>('page-main', {
  populate: 'deep',
});

const data = response.data as unknown as IMainPage;

useHead(usePageSeoMeta(data));

const categoriesStore = useCategoriesStore();
const portfoliosStore = usePortfoliosStore();
const promotionsStore = usePromotionsStore();

onMounted(() => {
  categoriesStore.getAll();
  portfoliosStore.getAll();
  promotionsStore.getAll();
});

const theCatalogProps = computed<ITheCatalogProps>(() => {
  return {
    categories: categoriesStore.data,
  };
});

const theHeroProps = computed<ITheHeroProps>(() => {
  return {
    promotion: promotionsStore.mainPromotion,
  };
});
</script>
