<template>
  <section class="catalog">
    <div class="container">
      <div class="catalog__title-wrapper">
        <h2 class="title catalog__title">
          {{ $t('theCatalog.title') }}
        </h2>
        <div class="catalog__title-line"></div>
      </div>
      <div class="catalog__swiper">
        <ClientOnly fallback-tag="span"
                    fallback="Загрузка слайдера...">
          <swiper-container class="swiper-wrapper"
                            v-bind="swiperOptions">
            <swiper-slide class="swiper-slide catalog__swiper-slide"
                          v-for="category in categories"
                          :key="category.id">
              <div class="catalog__pic">
                <img class="catalog__img"
                     loading="lazy"
                     :src="category.cover.url"
                     :alt="category.title">
              </div>
              <h3 class="catalog__sub-title">
                <NuxtLink :to="`/catalog/${category.slug}`"
                          class="catalog__link">
                  {{ category.title }}
                </NuxtLink>
              </h3>
            </swiper-slide>
          </swiper-container>
        </ClientOnly>
      </div>
      <div class="catalog__swiper-pagination"></div>
      <NuxtLink to="/catalog"
                class="btn btn__big btn__catalog">
        {{ $t('theCatalog.catalogButton') }}
      </NuxtLink>
    </div>
  </section>
</template>

<script setup lang="ts">
import { register } from 'swiper/element/bundle';
import type { ITheCatalogProps } from './types';

register(true);

const props = defineProps({
  categories: {
    type: Array as PropType<ITheCatalogProps['categories']>,
    required: true,
  },
});

const swiperOptions = {
  loop: false,
  lazy: true,
  slideClass: 'catalog__swiper-slide',
  pagination: {
    el: '.catalog__swiper-pagination',
    clickable: true,
  },

  slidesPerView: 4,
  slidesPerGroup: 4,
  grid: {
    rows: 1,
  },
  spaceBetween: 0,

  breakpoints: {
    320: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      grid: {
        rows: 1,
      },
      spaceBetween: 0,
    },

    610: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      grid: {
        rows: 1,
      },
      spaceBetween: 0,
    },

    900: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      grid: {
        rows: 1,
      },
      spaceBetween: 0,
    },

    1200: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      grid: {
        rows: 1,
      },
      spaceBetween: 0,
    },
  }
};
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
